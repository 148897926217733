import { Component, Vue } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import { SuppliersModal } from "./modals/suppliers.modal";
import { ModalComponent } from "@core/components/alt-ui/modal";
import {
    ICompany,
    ISettingsTableUpdateDto,
    ISupplierCreateDto,
    ISupplierUpdateDto,
    ITableSort,
    IUser,
    PermissionCommonSection,
    PermissionRight,
    TableType,
} from "@lib";
import { ISupplier } from "@lib";
import { getTableColumns } from "./settings-lists-suppliers-defaults";
import AltTable from "@/core/components/alt-table";

@Component({
    components: { BCard, BButton, ModalComponent, AltTable },
})
export default class SettingsListsSuppliers extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private suppliers: ISupplier[] = [];
    private suppliersTotal: number = 0;
    private table: any = {
        settings: {
            limit: undefined,
            sort: [],
        },
        page: 1,
        search: "",
    };

    private SupplierUseCase = this.$alt.system.usecase.createSupplierUseCase();

    private suppliersModal: SuppliersModal;

    public constructor() {
        super();

        this.suppliersModal = new SuppliersModal();
        this.suppliersModal.onCreate = this.create.bind(this);
        this.suppliersModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get sort(): ITableSort[] {
        return this.table.settings.sort ?? [];
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectSuppliers();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    protected openModalCreate(): void {
        this.suppliersModal.show();
    }

    protected openModalUpdate(supplier: ISupplier): void {
        this.suppliersModal.show(supplier);
    }

    private async confirmCopy(supplier: ISupplier): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Скопировать поставщика "${supplier.name}"?`,
            "Копирование поставщика",
            {
                acceptText: "Скопировать",
                color: "primary",
            },
        );

        if (result) {
            await this.copy(supplier);
        }
    }

    private async confirmDelete(supplier: ISupplier): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить поставщика: "${supplier.name}"?`,
            "Удаление поставщика",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(supplier);
        }
    }

    private async selectSuppliers(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.suppliers = await this.SupplierUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить поставщиков:\n${e.message}`);
        }
    }

    private async create(dto: ISupplierCreateDto): Promise<ISupplier | null> {
        try {
            this.$alt.loader.show();

            const supplier = await this.SupplierUseCase.create(this.company.id, dto);
            await this.selectSuppliers();
            this.$alt.toast.success(`Поставщик "${dto.name}" успешно создан.`, "Создание");

            return supplier;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать поставщика:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(supplier: ISupplier, dto: ISupplierUpdateDto): Promise<ISupplier | null> {
        try {
            this.$alt.loader.show();

            const response = await this.SupplierUseCase.update(this.company.id, supplier.id, dto);
            await this.selectSuppliers();
            this.$alt.toast.success(`Поставщик "${supplier.name}" успешно изменён.`, "Изменение");

            return response;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить поставщика:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async copy(supplier: ISupplier): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: ISupplierCreateDto = {
                sequence: supplier.sequence,
                name: supplier.name + " - копия",
                description: supplier.description,
                address: supplier.contacts.address,
                phone: supplier.contacts.phone,
                email: supplier.contacts.email,
            };
            await this.SupplierUseCase.create(this.company.id, dto);
            await this.selectSuppliers();
            this.$alt.toast.success(`Поставщик "${supplier.name}" успешно скопирован.`, "Копирование");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось скопировать поставщика:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(supplier: ISupplier): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.SupplierUseCase.archive(this.company.id, supplier.id);
            await this.selectSuppliers();
            this.$alt.toast.success(`Поставщик "${supplier.name}" успешно удалён.`, "Удаление");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить поставщика:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
