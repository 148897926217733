import { Vue, Component } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import { ICompany, ITaskStage, ITaskStageCreateDto, ITaskStageUpdateDto } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import AltTable from "@/core/components/alt-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { ITaskStageModalContext, TaskStageModal } from "./modals/task-stage.modal";
import { getTableColumns } from "./settings-lists-taskstages-defaults";
import { AppException } from "@/core/exceptions";

@Component({
    components: { BCard, BButton, AltTable, ModalComponent },
})
export default class SettingsListsTaskStages extends Vue {
    private featureEnabled = false;
    private company!: ICompany;

    private TaskStageUseCase = this.$alt.system.usecase.createTaskStageUseCase();
    private stageModal = new TaskStageModal();

    public stages: ITaskStage[] = [];

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.company = await this.$info.getCompany();
            this.featureEnabled = this.company.features?.projects?.enabled ?? false;
            await this.selectStages();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public async openModalCreate(): Promise<void> {
        const context: ITaskStageModalContext = {
            createHandler: dto => this.$alt.longOperation(() => this.create(dto), "Этап успешно создан."),
        };

        const success = await this.stageModal.show(context);

        if (success) {
            await this.$alt.longOperation(this.selectStages);
        }
    }

    public async openModalUpdate(stage: ITaskStage): Promise<void> {
        const context: ITaskStageModalContext = {
            stage: stage,
            updateHandler: (orig, dto) =>
                this.$alt.longOperation(() => this.update(orig, dto), "Этап успешно изменён."),
        };

        const success = await this.stageModal.show(context);

        if (success) {
            await this.$alt.longOperation(this.selectStages);
        }
    }

    public async confirmCopy(stage: ITaskStage): Promise<void> {
        const answer = await this.$alt.message.confirm(`Скопировать этап "${stage.name}"?`, "Копирование этапа", {
            acceptText: "Скопировать",
            color: "primary",
        });

        if (answer) {
            await this.$alt.longOperation(async () => {
                await this.copy(stage);
                this.$alt.toast.success("Этап успешно скопирован.");
                await this.selectStages();
            });
        }
    }

    public async confirmDelete(stage: ITaskStage): Promise<void> {
        const answer = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить этап: "${stage.name}"?`,
            "Удаление этапа",
            { acceptText: "Удалить" },
        );

        if (answer) {
            await this.$alt.longOperation(async () => {
                await this.delete(stage);
                this.$alt.toast.success("Этап успешно удалён.");
                await this.selectStages();
            });
        }
    }

    private async selectStages(): Promise<void> {
        try {
            if (!this.featureEnabled || !this.can.read) {
                return;
            }

            this.stages = await this.TaskStageUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить этапы задач:\n${e.message}`);
        }
    }

    private async create(dto: ITaskStageCreateDto): Promise<void> {
        try {
            await this.TaskStageUseCase.create(this.company.id, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось создать этап:\n${e.message}`);
        }
    }

    private async update(stage: ITaskStage, dto: ITaskStageUpdateDto): Promise<void> {
        try {
            await this.TaskStageUseCase.update(this.company.id, stage.id, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось изменить этап:\n${e.message}`);
        }
    }

    private async copy(stage: ITaskStage): Promise<void> {
        try {
            const dto: ITaskStageCreateDto = {
                projects: stage.projects,
                type: stage.type,
                name: stage.name + " - копия",
                description: stage.description,
                color: stage.color,
            };

            await this.TaskStageUseCase.create(this.company.id, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось скопировать этап:\n${e.message}`);
        }
    }

    private async delete(stage: ITaskStage): Promise<void> {
        try {
            await this.TaskStageUseCase.archive(this.company.id, stage.id);
        } catch (e: any) {
            throw new AppException(`Не удалось удалить этап:\n${e.message}`);
        }
    }
}
