import { Vue, Component } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import { IClientSource, ICompany, IUser } from "@lib";
import { IClientSourceCreateDto, IClientSourceUpdateDto } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import AltTable from "@/core/components/alt-table";
import { getTableColumns } from "./settings-lists-clientsources-defaults";
import { ClientSourcesModal } from "./modals/clientsources.modal";
import { ModalComponent } from "@core/components/alt-ui/modal";

@Component({
    components: { BCard, BButton, AltTable, ModalComponent },
})
export default class SettingsListsClientSources extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private sources: IClientSource[] = [];

    private ClientSourceUseCase = this.$alt.system.usecase.createClientSourceUseCase();

    private clientSourcesModal: ClientSourcesModal;

    public constructor() {
        super();

        this.clientSourcesModal = new ClientSourcesModal();
        this.clientSourcesModal.onCreate = this.create.bind(this);
        this.clientSourcesModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectClientSources();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    protected openModalCreate(): void {
        this.clientSourcesModal.show();
    }

    protected openModalUpdate(source: IClientSource): void {
        this.clientSourcesModal.show(source);
    }

    private async confirmCopy(source: IClientSource): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Скопировать источник "${source.name}"?`,
            "Копирование источника",
            { acceptText: "Скопировать", color: "primary" },
        );

        if (result) {
            await this.copy(source);
        }
    }

    private async confirmDelete(source: IClientSource): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить источник: "${source.name}"?`,
            "Удаление источника",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(source);
        }
    }

    private async selectClientSources(): Promise<IClientSource | void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.sources = await this.ClientSourceUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить источники клиентов:\n${e.message}`);
        }
    }

    private async create(dto: IClientSourceCreateDto): Promise<IClientSource | null> {
        try {
            this.$alt.loader.show();

            const response = await this.ClientSourceUseCase.create(this.company.id, dto);
            await this.selectClientSources();

            this.$alt.toast.success(`Источник "${dto.name}" успешно создан.`, "Создание");

            return response;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать источник:\n${e.message}`);

            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(clientSource: IClientSource, dto: IClientSourceUpdateDto): Promise<IClientSource | null> {
        try {
            this.$alt.loader.show();

            const response = await this.ClientSourceUseCase.update(this.company.id, clientSource.id, dto);
            await this.selectClientSources();

            this.$alt.toast.success(`Источник "${dto.name}" успешно изменён.`, "Изменение");

            return response;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить источник:\n${e.message}`);

            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async copy(source: IClientSource): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: IClientSourceCreateDto = {
                //sequence: undefined,
                name: source.name + " - копия",
                description: source.description,
            };
            await this.ClientSourceUseCase.create(this.company.id, dto);
            await this.selectClientSources();
            this.$alt.toast.success(`Источник "${source.name}" успешно скопирован.`, "Копирование");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось скопировать источник:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(source: IClientSource): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.ClientSourceUseCase.archive(this.company.id, source.id);
            await this.selectClientSources();
            this.$alt.toast.success(`Источник "${source.name}" успешно удалён.`, "Удаление");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить источник:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
