import { Vue, Component } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import { IGoodCategory, ICompany, IStore, IUser } from "@lib";
import { IGoodCategoryCreateDto, IGoodCategoryUpdateDto } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import AltTable from "@/core/components/alt-table";
import { getTableColumns } from "./settings-lists-categories-defaults";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { CategoriesModal } from "./modals/categories.modal";

@Component({
    components: { BCard, BButton, AltTable, ModalComponent },
})
export default class SettingsListsCategories extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private stores: IStore[] = [];
    private categories: IGoodCategory[] = [];

    private CategoryUseCase = this.$alt.system.usecase.createGoodCategoryUseCase();

    private categoriesModal: CategoriesModal;

    public constructor() {
        super();

        this.categoriesModal = new CategoriesModal();
        this.categoriesModal.onCreate = this.create.bind(this);
        this.categoriesModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.stores = await this.$info.getStores();
            await this.selectCategories();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    protected openModalCreate(): void {
        this.categoriesModal.show({
            stores: this.stores,
        });
    }

    protected openModalUpdate(category: IGoodCategory): void {
        this.categoriesModal.show({
            stores: this.stores,
            category,
        });
    }

    private async confirmCopy(category: IGoodCategory): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Скопировать категорию "${category.name}"?`,
            "Копирование категории",
            {
                acceptText: "Скопировать",
                color: "primary",
            },
        );

        if (result) {
            await this.copy(category);
        }
    }

    private async confirmDelete(category: IGoodCategory): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить категорию: "${category.name}"?`,
            "Удаление категории",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(category);
        }
    }

    private async selectCategories(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.categories = await this.CategoryUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить категории товаров:\n${e.message}`);
        }
    }

    private async create(dto: IGoodCategoryCreateDto): Promise<IGoodCategory | null> {
        try {
            this.$alt.loader.show();

            const response = await this.CategoryUseCase.create(this.company.id, dto);
            await this.selectCategories();
            this.$alt.toast.success(`Категория "${dto.name}" успешно создана.`, "Создание");

            return response;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать категорию:\n${e.message}`);

            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(category: IGoodCategory, dto: IGoodCategoryUpdateDto): Promise<IGoodCategory | null> {
        try {
            this.$alt.loader.show();

            const response = await this.CategoryUseCase.update(this.company.id, category.id, dto);
            await this.selectCategories();
            this.$alt.toast.success(`Категория "${category.name}" успешно изменена.`, "Изменение");

            return response;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить категорию:\n${e.message}`);

            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async copy(category: IGoodCategory): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: IGoodCategoryCreateDto = {
                stores: (category.stores as IStore[]).map(e => e.id),
                name: category.name + " - копия",
                description: category.description,
                color: category.color,
            };
            await this.CategoryUseCase.create(this.company.id, dto);
            await this.selectCategories();
            this.$alt.toast.success(`Категория "${category.name}" успешно скопирована.`, "Копирование");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось скопировать категорию:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(category: IGoodCategory): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.CategoryUseCase.archive(this.company.id, category.id);
            await this.selectCategories();
            this.$alt.toast.success(`Категория "${category.name}" успешно удалена.`, "Удаление");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить категорию:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
