import { Vue, Component } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import { ICompany, IProductType, IUser } from "@lib";
import { IProductTypeCreateDto, IProductTypeUpdateDto } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import AltTable from "@/core/components/alt-table";
import { getTableColumns } from "./settings-lists-producttypes-defaults";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { ProductTypesModal } from "./modals/producttypes.modal";

@Component({
    components: { BCard, BButton, AltTable, ModalComponent },
})
export default class SettingsListsProductTypes extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private types: IProductType[] = [];

    private ProductTypeUseCase = this.$alt.system.usecase.createProductTypeUseCase();

    private productTypesModal: ProductTypesModal;

    public constructor() {
        super();

        this.productTypesModal = new ProductTypesModal();
        this.productTypesModal.onCreate = this.create.bind(this);
        this.productTypesModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectProductTypes();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    protected openModalCreate(): void {
        this.productTypesModal.show();
    }

    protected openModalUpdate(productType: IProductType): void {
        this.productTypesModal.show(productType);
    }

    private async confirmCopy(type: IProductType): Promise<void> {
        const result = await this.$alt.message.confirm(`Скопировать тип "${type.name}"?`, "Копирование типа", {
            acceptText: "Скопировать",
            color: "primary",
        });

        if (result) {
            await this.copy(type);
        }
    }

    private async confirmDelete(type: IProductType): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить тип: "${type.name}"?`,
            "Удаление типа",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(type);
        }
    }

    private async selectProductTypes(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.types = await this.ProductTypeUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить типы продуктов:\n${e.message}`);
        }
    }

    private async create(dto: IProductTypeCreateDto): Promise<IProductType | null> {
        try {
            this.$alt.loader.show();

            const response = await this.ProductTypeUseCase.create(this.company.id, dto);
            await this.selectProductTypes();
            this.$alt.toast.success(`Тип "${dto.name}" успешно создан.`, "Создание");

            return response;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать тип:\n${e.message}`);

            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(productType: IProductType, dto: IProductTypeUpdateDto): Promise<IProductType | null> {
        try {
            this.$alt.loader.show();

            const response = await this.ProductTypeUseCase.update(this.company.id, productType.id, dto);
            await this.selectProductTypes();
            this.$alt.toast.success(`Тип "${productType.name}" успешно изменён.`, "Изменение");

            return response;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить тип:\n${e.message}`);

            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async copy(type: IProductType): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: IProductTypeCreateDto = {
                //sequence: undefined,
                name: type.name + " - копия",
                description: type.description,
            };
            await this.ProductTypeUseCase.create(this.company.id, dto);
            await this.selectProductTypes();
            this.$alt.toast.success(`Тип "${type.name}" успешно скопирован.`, "Копирование");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось скопировать тип:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(type: IProductType): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.ProductTypeUseCase.archive(this.company.id, type.id);
            await this.selectProductTypes();
            this.$alt.toast.success(`Тип "${type.name}" успешно удалён.`, "Удаление");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить тип:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
