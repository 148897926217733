import { IOrderType, IOrderTypeCreateDto, IOrderTypeFieldGroup, IOrderTypeTemplate, IOrderTypeUpdateDto } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Divider, Panel, Select, TextBox } from "@core/components/alt-ui/controls";
import { OrderTypeGroups } from "@core/controls/order-type-groups/order-type-groups";
import { MacroFieldAssociation } from "@core/controls/field-modal/field.modal";
import { ObjectUtils } from "@/utils/object.utils";

export interface IOrderTypesModalContext {
    types: IOrderType[];
    type?: IOrderType;
    templates: IOrderTypeTemplate[];
}

export class OrderTypesModal extends Modal<IOrderTypesModalContext> {
    private tbSequence!: TextBox;

    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private cbTemplate!: Select<IOrderTypeTemplate>;
    private dvSecondSectionDivider!: Divider;
    private customOrderTypeGroups!: OrderTypeGroups;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context: IOrderTypesModalContext | null = null;
    private orderType: IOrderType | null = null;

    public onModalShow: (() => void) | null = null;
    public onModalHide: (() => void) | null = null;

    public onCreate: ((dto: IOrderTypeCreateDto) => Promise<IOrderType | null>) | null = null;
    public onUpdate: ((orderType: IOrderType, dto: IOrderTypeUpdateDto) => Promise<IOrderType | null>) | null = null;

    public constructor() {
        super("order-types-modal", "");
        this.addVisibleChangedHandler(this.changeModalVisible.bind(this));
        this.initializeControls();
    }

    public show(context: IOrderTypesModalContext): Promise<void> {
        this.context = context;
        this.orderType = context.type ? ObjectUtils.clone(context.type) : null;
        this.initializeControls();

        if (this.orderType) {
            this.title = "Изменение типа";
            this.populateControls(this.orderType);
        } else {
            this.title = "Новый тип";
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbSequence = new TextBox();
        this.tbName = new TextBox();
        this.tbDescription = new TextBox();
        this.cbTemplate = new Select<IOrderTypeTemplate>();
        this.dvSecondSectionDivider = new Divider();
        this.customOrderTypeGroups = new OrderTypeGroups();
        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        this.tbSequence.id = "productType.sequence";
        this.tbSequence.label = "Порядок";
        this.tbSequence.validation = "required|numeric|min_value:1|max_value:999";
        this.tbSequence.visible = false;

        this.tbName.id = "orderType.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.tbDescription.id = "orderType.description";
        this.tbDescription.label = "Описание";

        this.cbTemplate.id = "orderType.template";
        this.cbTemplate.label = "Шаблон";
        this.cbTemplate.help = "Готовый шаблон типа заявки с полями. Вы можете изменить заданные поля.";
        this.cbTemplate.items = this.context?.templates ?? [];
        this.cbTemplate.selectedIndex = 0;
        this.cbTemplate.textField = opt => opt.name;
        this.cbTemplate.descriptionField = opt => opt.description;
        this.cbTemplate.addChangedHandler(() => this.updateCustomOrderTypeGroups());

        this.customOrderTypeGroups.macros = this.getTakenMacros();

        this.updateCustomOrderTypeGroups();

        //

        this.btnCancel.id = "orderType.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "orderType.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    public get controls(): Control[] {
        return [
            this.tbSequence,
            this.tbName,
            this.tbDescription,
            this.cbTemplate,
            this.dvSecondSectionDivider,
            this.customOrderTypeGroups,
            this.pnlFooter,
        ];
    }

    private async changeModalVisible(sender: any, e: any): Promise<void> {
        if (e.visible) {
            if (this.onModalShow) {
                this.onModalShow();
            }
        } else {
            if (this.onModalHide) {
                this.onModalHide();
            }
        }
    }

    private updateCustomOrderTypeGroups(): void {
        if (this.cbTemplate.selectedItem) {
            this.customOrderTypeGroups.items = ObjectUtils.clone(this.cbTemplate.selectedItem.groups);
            this.customOrderTypeGroups.itemsId = this.cbTemplate.selectedItem.id;
        }
    }

    private getTakenMacros(): MacroFieldAssociation[] {
        if (!this.context) {
            return [];
        }

        const macros: MacroFieldAssociation[] = [];
        for (const type of this.context.types) {
            for (const group of type.groups) {
                for (const field of group.fields) {
                    if (!field.macros) {
                        continue;
                    }

                    for (const macro of field.macros) {
                        macros.push({ macro: macro, field: field });
                    }
                }
            }
        }

        return macros;
    }

    private populateControls(orderType: IOrderType): void {
        this.tbSequence.text = orderType.sequence?.toString() ?? "";
        this.tbSequence.visible = true;
        this.tbName.text = orderType.name;
        this.tbDescription.text = orderType.description ?? "";
        this.cbTemplate.visible = false;
        this.customOrderTypeGroups.items = ObjectUtils.clone(orderType.groups);
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const result = this.orderType ? await this.update(this.orderType) : await this.create();

        if (result) {
            this.hide(result);
        }
    }

    private async create(): Promise<IOrderType | null> {
        if (!this.onCreate) {
            return null;
        }

        const dto: IOrderTypeCreateDto = {
            name: this.tbName.text,
            description: this.tbDescription.text,
            groups: this.customOrderTypeGroups.items,
        };

        return await this.onCreate(dto);
    }

    private async update(orderType: IOrderType): Promise<IOrderType | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: IOrderTypeUpdateDto = {
            sequence: parseInt(this.tbSequence.text),
            name: this.tbName.text,
            description: this.tbDescription.text,
            groups: this.customOrderTypeGroups.items,
        };

        return await this.onUpdate(orderType, dto);
    }
}
