import { ITransactionItem, TransactionItemTag, TransactionType } from "@lib";
import { ISelectOption } from "@core/types/common/select-options";

export function getTransactionType(): ISelectOption<TransactionType>[] {
    return [
        { id: TransactionType.Income, name: "Доход" },
        { id: TransactionType.Expense, name: "Расход" },
    ];
}

export function getTransactionItemTag(): ISelectOption<TransactionItemTag>[] {
    return [
        { id: TransactionItemTag.Prepayment, name: "Предоплата заявки/продажи" },
        { id: TransactionItemTag.Payment, name: "Оплата заявки/продажи" },
        { id: TransactionItemTag.Refund, name: "Возврат средств" },
        { id: TransactionItemTag.Salary, name: "Зарплата сотрудника" },
        { id: TransactionItemTag.Fine, name: "Штраф сотрудника" },
        { id: TransactionItemTag.SupplierExpenses, name: "Расходы на товары/материалы" },
        { id: TransactionItemTag.TransferIncome, name: "Входящий перевод" },
        { id: TransactionItemTag.TransferExpense, name: "Исходящий перевод" },
        { id: TransactionItemTag.OtherIncomes, name: "Прочие доходы" },
        { id: TransactionItemTag.OtherExpenses, name: "Прочие расходы" },
    ];
}

export function getTableColumns(context: any): any[] {
    const types = getTransactionType();
    const tags = getTransactionItemTag();
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 90,
            hide: !context.can.update && !context.can.create && !context.can.delete,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.update,
                            click: (type: ITransactionItem) => context.openModalUpdate(type),
                        },
                        {
                            id: "copy",
                            description: "Скопировать",
                            icon: "CopyIcon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.create,
                            click: (type: ITransactionItem) => context.confirmCopy(type),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: () => context.can.delete,
                            click: (type: ITransactionItem) => context.confirmDelete(type),
                        },
                    ],
                };
            },
        },
        {
            colId: "sequence",
            field: "sequence",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 75,
            hide: false,
        },
        {
            colId: "type",
            field: "type",
            headerName: "Тип",
            sortable: true,
            filter: true,
            width: 100,
            hide: false,
            cellRenderer: function (params: any) {
                if (!params.value) {
                    return "";
                }
                const type = types.find(t => t.id === params.value);
                return type ? type.name : "";
            },
        },
        {
            colId: "name",
            field: "name",
            headerName: "Название",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        {
            colId: "description",
            field: "description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 300,
            hide: false,
        },
        {
            colId: "tags",
            field: "tags",
            headerName: "Метки",
            sortable: false,
            filter: false,
            width: 200,
            hide: false,
            cellRenderer: function (params: any) {
                if (!params.value) {
                    return "";
                }

                let result = "";
                const selected = params.value as string[];
                for (const one of selected) {
                    const tag = tags.find(t => t.id === one);
                    if (result.length > 0) {
                        result += ", ";
                    } //"<br>";
                    result += tag ? tag.name : "";
                }
                return result;
            },
        },
    ];
}
