import { Vue, Component } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import {
    IOrderType,
    IOrderTypeCreateDto,
    IOrderTypeUpdateDto,
    IUser,
    ICompany,
    FormType,
    ISettingsFormUpdateDto,
    IOrderTypeTemplate,
    OrderTypeFieldGroupType,
} from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import AltTable from "@/core/components/alt-table";
import { getOrderTypesTableColumns } from "./settings-lists-ordertypes-defaults";
import { OrderTypesModal } from "./modals/order-type.modal";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { getDefaultFields as getClientDefaultFields } from "../../clients/clients-defaults";
import { Diagnostics } from "@/utils/diagnostics";

@Component({
    components: { BCard, BButton, AltTable, ModalComponent },
})
export default class SettingsListsOrderTypes extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private types: IOrderType[] = [];
    private templates: IOrderTypeTemplate[] = [];

    private OrderTypeUseCase = this.$alt.system.usecase.createOrderTypeUseCase();

    private orderTypesModal: OrderTypesModal;

    public constructor() {
        super();

        this.orderTypesModal = new OrderTypesModal();
        this.orderTypesModal.onCreate = this.create.bind(this);
        this.orderTypesModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getOrderTypesTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await Promise.all([this.selectOrderTypeTemplates(), this.selectOrderTypes()]);
            this.initParams();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async initParams(): Promise<void> {
        try {
            if (this.$route.query.new) {
                return this.openModalCreate();
            }

            if (this.$route.query.id) {
                const typeId =
                    this.$route.query.id instanceof Array ? (this.$route.query.id[0] as string) : this.$route.query.id;
                const type = await this.getOrderType(typeId);
                return this.openModalUpdate(type);
            }
        } catch (e: any) {
            await this.$router.push({ query: undefined }).catch(_ => {});
        }
    }

    private async getOrderType(id: string): Promise<any> {
        try {
            const client = await this.OrderTypeUseCase.get(this.company.id, id);
            if (!client) {
                throw new Error("Тип заявки не найден.");
            }

            return client;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить тип заявки:\n${e.message}`);
        }
    }

    private async openModalCreate(): Promise<void> {
        this.orderTypesModal.onModalShow = () => this.$router.push({ query: { new: "" } }).catch(() => {});
        this.orderTypesModal.onModalHide = () => this.$router.push({ query: undefined }).catch(() => {});

        await this.orderTypesModal.show({
            types: this.types,
            templates: this.templates,
        });
    }

    private async openModalUpdate(type: IOrderType): Promise<void> {
        this.orderTypesModal.onModalShow = () => this.$router.push({ query: { id: type.id } }).catch(() => {});
        this.orderTypesModal.onModalHide = () => this.$router.push({ query: undefined }).catch(() => {});

        await this.orderTypesModal.show({
            types: this.types,
            type: type,
            templates: this.templates,
        });
    }

    private async confirmCopy(type: IOrderType): Promise<void> {
        const result = await this.$alt.message.confirm(`Скопировать тип заявки "${type.name}"?`, "Копирование типа", {
            acceptText: "Скопировать",
            color: "primary",
        });

        if (result) {
            await this.copy(type);
        }
    }

    private async confirmDelete(type: IOrderType): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить тип: "${type.name}"?`,
            "Удаление типа",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(type);
        }
    }

    private async selectOrderTypeTemplates(): Promise<void> {
        try {
            this.templates = await this.OrderTypeUseCase.selectTemplates(this.company.id);
        } catch (e: any) {
            this.templates = [];
        }
    }

    private async selectOrderTypes(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.types = await this.OrderTypeUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить типы заявок:\n${e.message}`);
        }
    }

    private async create(dto: IOrderTypeCreateDto): Promise<IOrderType | null> {
        try {
            this.$alt.loader.show();
            const type = await this.OrderTypeUseCase.create(this.company.id, dto);
            await this.selectOrderTypes();
            this.$alt.toast.success(`Тип "${dto.name}" успешно создан.`, "Создание");
            await this.updateClientFormSettings(type);
            return type;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать тип:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(orig: IOrderType, dto: IOrderTypeUpdateDto): Promise<IOrderType | null> {
        try {
            this.$alt.loader.show();
            const type = await this.OrderTypeUseCase.update(this.company.id, orig.id, dto);
            await this.selectOrderTypes();
            this.$alt.toast.success(`Тип "${dto.name}" успешно изменён.`, "Изменение");
            await this.updateClientFormSettings(type);
            return type;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить тип:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async updateClientFormSettings(type: IOrderType): Promise<void> {
        try {
            const clientGroup = type.groups.find(g => g.type === OrderTypeFieldGroupType.Client);
            if (!clientGroup) {
                return;
            }

            const clientForm = await this.$alt.system.usecase
                .createSettingsFormUseCase()
                .get(this.company.id, FormType.Client);

            const clientFormFields = clientForm?.fields ?? getClientDefaultFields();

            let hasNewCustomFields = false;
            for (const field of clientGroup.fields) {
                if (!field.custom) {
                    continue;
                }

                // TODO: избавиться от поиска по id, оставить только customId
                const clientFormField = clientFormFields.find(
                    f => (f.customId && field.customId && f.customId === field.customId) || f.id === field.id,
                );
                if (!clientFormField) {
                    const newField = this.$alt.clone(field);
                    newField.hidden = true;
                    // В форме клиентов id - это objectId, а в типах заявок просто произвольная строка.
                    // Чтобы не было ошибки, удаляем id - тоогда он сгенерируется для формы клиентов.
                    // TODO: сделать в типе заявок тоже objectId
                    newField.id = undefined as any;
                    (newField as any)._id = undefined as any;

                    clientFormFields.push(newField);
                    hasNewCustomFields = true;
                }
            }

            if (hasNewCustomFields) {
                const dto: ISettingsFormUpdateDto = { fields: clientFormFields };
                await this.$alt.system.usecase
                    .createSettingsFormUseCase()
                    .update(this.company.id, FormType.Client, dto);
            }
        } catch (e: any) {
            Diagnostics.LogWarning(`Не удалось изменить настройки формы клиентов: ${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async copy(type: IOrderType): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: IOrderTypeCreateDto = {
                offices: type.offices as string[],
                sequence: undefined,
                name: type.name + " - копия",
                description: type.description,
                groups: type.groups,
            };
            await this.OrderTypeUseCase.create(this.company.id, dto);
            await this.selectOrderTypes();
            this.$alt.toast.success(`Тип "${type.name}" успешно скопирован.`, "Копирование");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось скопировать тип:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(type: IOrderType): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.OrderTypeUseCase.archive(this.company.id, type.id);
            await this.selectOrderTypes();
            this.$alt.toast.success(`Тип "${type.name}" успешно удалён.`, "Удаление");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить тип:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
