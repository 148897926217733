import { ITaskStage, ITaskStageCreateDto, ITaskStageUpdateDto, TaskStageType } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, ColorBox, Control, Panel, Select, TextBox } from "@core/components/alt-ui/controls";
import { ISelectOption } from "@core/types/common/select-options";
import { getTaskStageTypes } from "../settings-lists-taskstages-defaults";

export interface ITaskStageModalContext {
    /** Этап для редактирования. */
    stage?: ITaskStage;

    /** Обработчик создания этапа. */
    createHandler?: (dto: ITaskStageCreateDto) => Promise<boolean>;

    /** Обработчик обновления этапа. */
    updateHandler?: (stage: ITaskStage, dto: ITaskStageUpdateDto) => Promise<boolean>;
}

export class TaskStageModal extends Modal<ITaskStageModalContext, boolean> {
    private tbSequence!: TextBox;
    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private cbType!: Select<ISelectOption<TaskStageType | null>>;
    private clColor!: ColorBox;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context?: ITaskStageModalContext;

    public constructor() {
        super("task-stage-modal", "");
        this.initializeControls();
    }

    public show(context: ITaskStageModalContext): Promise<boolean> {
        this.context = context;
        this.title = context.stage ? "Изменение этапа" : "Новый этап";
        this.initializeControls();

        if (context.stage) {
            this.populateControls(context.stage);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbSequence = new TextBox();
        this.tbSequence.id = "stage.sequence";
        this.tbSequence.label = "Порядок";
        this.tbSequence.validation = "required|numeric|min_value:1|max_value:999";
        this.tbSequence.visible = false;

        this.tbName = new TextBox();
        this.tbName.id = "stage.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.tbDescription = new TextBox();
        this.tbDescription.id = "stage.description";
        this.tbDescription.label = "Описание";

        this.cbType = new Select<ISelectOption<TaskStageType | null>>();
        this.cbType.id = "stage.type";
        this.cbType.label = "Тип";
        this.cbType.items = getTaskStageTypes();
        this.cbType.textField = opt => opt.name;
        this.cbType.selectedIndex = 0;

        this.clColor = new ColorBox();
        this.clColor.id = "stage.color";
        this.clColor.label = "Цвет";
        this.clColor.value = "#98afff";

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "stage.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(() => this.clickCancel());

        this.btnSave = new Button();
        this.btnSave.id = "stage.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(() => this.clickSave());

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(stage: ITaskStage): void {
        this.tbSequence.text = stage.sequence.toString();
        this.tbSequence.visible = true;
        this.tbName.text = stage.name;
        this.tbDescription.text = stage.description ?? "";
        this.clColor.value = stage.color ?? "#98afff";

        const typeIndex = this.cbType.items.findIndex(i => i.id === stage.type);
        this.cbType.selectedIndex = typeIndex >= 0 ? typeIndex : 0;
    }

    public get controls(): Control[] {
        return [this.tbSequence, this.tbName, this.tbDescription, this.cbType, this.clColor, this.pnlFooter];
    }

    private async clickCancel(): Promise<void> {
        this.hide(false);
    }

    private async clickSave(): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const ok = this.context?.stage ? await this.updateStage(this.context.stage) : await this.createStage();

        if (ok) {
            this.hide(true);
        }
    }

    private async createStage(): Promise<boolean> {
        if (!this.context?.createHandler) {
            return false;
        }

        const dto: ITaskStageCreateDto = {
            type: this.cbType.selectedItem?.id ?? undefined,
            name: this.tbName.text,
            description: this.tbDescription.text.length > 0 ? this.tbDescription.text : undefined,
            color: this.clColor.value,
        };

        return await this.context.createHandler(dto);
    }

    private async updateStage(stage: ITaskStage): Promise<boolean> {
        if (!this.context?.updateHandler) {
            return false;
        }

        const dto: ITaskStageUpdateDto = {
            sequence: parseInt(this.tbSequence.text),
            type: this.cbType.selectedItem?.id ?? undefined,
            name: this.tbName.text,
            description: this.tbDescription.text.length > 0 ? this.tbDescription.text : undefined,
            color: this.clColor.value,
        };

        return await this.context.updateHandler(stage, dto);
    }
}
