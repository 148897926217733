import { IOrderStage, OrderStageType } from "@lib";
import { ISelectOption } from "@core/types/common/select-options";

export function getOrderStageTypes(): ISelectOption<OrderStageType | null>[] {
    return [
        { id: null, name: "<не указан>" },
        { id: OrderStageType.New, name: "ТОЛЬКО ЧТО СОЗДАННЫЕ ЗАЯВКИ" },
        { id: OrderStageType.InWork, name: "ЗАЯВКИ В РАБОТЕ" },
        { id: OrderStageType.Deferred, name: "ОТЛОЖЕННЫЕ ЗАЯВКИ" },
        { id: OrderStageType.Ready, name: "ГОТОВЫЕ ЗАЯВКИ" },
        { id: OrderStageType.Closed, name: "ЗАКРЫТЫЕ ЗАЯВКИ" },
        { id: OrderStageType.Canceled, name: "ОТМЕНЁННЫЕ ЗАЯВКИ" },
    ];
}

export function getTableColumns(context: any): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 90,
            hide: !context.can.update && !context.can.create && !context.can.delete,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.update,
                            click: (stage: IOrderStage) => context.openModalUpdate(stage),
                        },
                        {
                            id: "copy",
                            description: "Скопировать",
                            icon: "CopyIcon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.create,
                            click: (stage: IOrderStage) => context.confirmCopy(stage),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: () => context.can.delete,
                            click: (stage: IOrderStage) => context.confirmDelete(stage),
                        },
                    ],
                };
            },
        },
        {
            colId: "sequence",
            field: "sequence",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 70,
            hide: false,
        },
        {
            colId: "name",
            field: "name",
            headerName: "Название",
            sortable: true,
            filter: true,
            width: 200,
            hide: false,
        },
        {
            colId: "description",
            field: "description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 300,
            hide: false,
        },
        {
            colId: "type",
            field: "type",
            headerName: "Тип",
            sortable: false,
            filter: true,
            width: 200,
            hide: false,
            cellRenderer: function (params: any) {
                if (!params.value) {
                    return "";
                }
                const types = getOrderStageTypes();
                const type = types.find(t => t.id === params.value);
                return type ? type.name : "";
            },
        },
        {
            colId: "color",
            field: "color",
            headerName: "Цвет",
            sortable: true,
            filter: true,
            width: 200,
            hide: false,

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: any) => {
                return {
                    color: params.value,
                    name: params.value,
                    click: () => context.openModalUpdate(params.data),
                };
            },
        },
    ];
}
