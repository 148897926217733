import { Component, Emit, Vue } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { PermissionCommonSection, PermissionRight } from "@lib";
import AltAutoButton from "@/core/components/alt-auto-button";

@Component({
    name: "settings-lists-works-toolbar",
    components: { BButton, AltAutoButton },
})
export default class SettingsListsWorksToolbar extends Vue {
    @Emit("open-modal-create")
    private openModalCreate(): void {
        return;
    }

    @Emit("open-modal-import")
    private openModalImport(): void {
        return;
    }

    @Emit("open-modal-export")
    private openModalExport(): void {
        return;
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Create);
            },
        };
    }
}
