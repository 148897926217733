import { IOrderStage, IOrderStageCreateDto, IOrderStageUpdateDto, OrderStageType } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, ColorBox, Control, Panel, Select, TextBox } from "@core/components/alt-ui/controls";
import { ISelectOption } from "@core/types/common/select-options";
import { getOrderStageTypes } from "../settings-lists-orderstages-defaults";

export class OrderStageModal extends Modal<IOrderStage> {
    private tbSequence!: TextBox;
    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private cbType!: Select<ISelectOption<OrderStageType | null>>;
    private clColor!: ColorBox;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private stage: IOrderStage | null = null;
    public onCreate: ((dto: IOrderStageCreateDto) => Promise<IOrderStage | null>) | null = null;
    public onUpdate: ((stage: IOrderStage, dto: IOrderStageUpdateDto) => Promise<IOrderStage | null>) | null = null;

    public constructor() {
        super("order-stage-modal", "");
        this.initializeControls();
    }

    public show(stage?: IOrderStage): Promise<void> {
        this.stage = stage ?? null;
        this.title = stage ? "Изменение этапа" : "Новый этап";
        this.initializeControls();

        if (stage) {
            this.populateControls(stage);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbSequence = new TextBox();
        this.tbName = new TextBox();
        this.tbDescription = new TextBox();
        this.cbType = new Select<ISelectOption<OrderStageType | null>>();
        this.clColor = new ColorBox();
        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        this.tbSequence.id = "stage.sequence";
        this.tbSequence.label = "Порядок";
        this.tbSequence.validation = "required|numeric|min_value:1|max_value:999";
        this.tbSequence.visible = false;

        this.tbName.id = "stage.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.tbDescription.id = "stage.description";
        this.tbDescription.label = "Описание";

        this.cbType.id = "stage.type";
        this.cbType.label = "Тип";
        this.cbType.items = getOrderStageTypes();
        this.cbType.textField = opt => opt.name;
        this.cbType.selectedIndex = 0;

        this.clColor.id = "stage.color";
        this.clColor.label = "Цвет";
        this.clColor.value = "#98afff";

        //

        this.btnCancel.id = "stage.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "stage.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(stage: IOrderStage): void {
        this.tbSequence.text = stage.sequence.toString();
        this.tbSequence.visible = true;
        this.tbName.text = stage.name;
        this.tbDescription.text = stage.description ?? "";
        this.clColor.value = stage.color ?? "#98afff";

        const typeIndex = this.cbType.items.findIndex(i => i.id === stage.type);
        this.cbType.selectedIndex = typeIndex >= 0 ? typeIndex : 0;
    }

    public get controls(): Control[] {
        return [this.tbSequence, this.tbName, this.tbDescription, this.cbType, this.clColor, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.stage ? await this.updateStage(this.stage) : await this.createStage();

        if (result) {
            this.hide(result);
        }
    }

    private async createStage(): Promise<IOrderStage | null> {
        if (!this.onCreate) {
            return null;
        }

        const dto: IOrderStageCreateDto = {
            type: this.cbType.selectedItem?.id as OrderStageType,
            name: this.tbName.text,
            description: this.tbDescription.text.length > 0 ? this.tbDescription.text : undefined,
            color: this.clColor.value,
        };

        return await this.onCreate(dto);
    }

    private async updateStage(stage: IOrderStage): Promise<IOrderStage | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: IOrderStageUpdateDto = {
            sequence: parseInt(this.tbSequence.text),
            type: this.cbType.selectedItem?.id ?? undefined,
            name: this.tbName.text,
            description: this.tbDescription.text.length > 0 ? this.tbDescription.text : undefined,
            color: this.clColor.value,
        };

        return await this.onUpdate(stage, dto);
    }
}
