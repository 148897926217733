import { ISupplier, ISupplierCreateDto, ISupplierUpdateDto } from "@lib";
import { Button, Control, Panel, PhoneBox, TextArea, TextBox } from "@core/components/alt-ui/controls";
import { Modal } from "@core/components/alt-ui/modal";

export class SuppliersModal extends Modal<ISupplier> {
    private tbSequence!: TextBox;
    private tbName!: TextBox;
    private taDescription!: TextArea;
    private tbAddress!: TextBox;
    private phPhone!: PhoneBox;
    private tbEmail!: TextBox;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private supplier: ISupplier | null = null;
    public onCreate: ((dto: ISupplierCreateDto) => Promise<ISupplier | null>) | null = null;
    public onUpdate: ((supplier: ISupplier, dto: ISupplierUpdateDto) => Promise<ISupplier | null>) | null = null;

    public constructor() {
        super("suppliers-modal", "");
        this.initializeControls();
    }

    public show(supplier?: ISupplier): Promise<void> {
        this.supplier = supplier ?? null;
        this.title = supplier ? "Изменение поставщика" : "Новый поставщик";
        this.initializeControls();

        if (supplier) {
            this.populateControls(supplier);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbSequence = new TextBox();
        this.tbSequence.id = "supplier.sequence";
        this.tbSequence.label = "Порядок";
        this.tbSequence.validation = "required|numeric|min_value:1|max_value:999";
        this.tbSequence.visible = false;

        this.tbName = new TextBox();
        this.tbName.id = "supplier.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.taDescription = new TextArea();
        this.taDescription.id = "supplier.description";
        this.taDescription.label = "Описание";

        this.tbAddress = new TextBox();
        this.tbAddress.id = "supplier.address";
        this.tbAddress.label = "Адрес";

        this.phPhone = new PhoneBox();
        this.phPhone.id = "supplier.phone";
        this.phPhone.label = "Телефон";

        this.tbEmail = new TextBox();
        this.tbEmail.id = "supplier.email";
        this.tbEmail.label = "Электронная почта";
        this.tbEmail.validation = "email|max:40";
        this.tbEmail.text = "";

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "supplier.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "supplier.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(supplier: ISupplier): void {
        this.tbSequence.text = supplier.sequence.toString();
        this.tbSequence.visible = true;
        this.tbName.text = supplier.name;
        this.taDescription.text = supplier.description ?? "";
        this.tbAddress.text = supplier.contacts?.address ?? "";
        this.phPhone.text = supplier.contacts?.phone ?? "";
        this.tbEmail.text = supplier.contacts?.email ?? "";
    }

    public get controls(): Control[] {
        return [
            this.tbSequence,
            this.tbName,
            this.taDescription,
            this.tbAddress,
            this.phPhone,
            this.tbEmail,
            this.pnlFooter,
        ];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const result = this.supplier ? await this.update(this.supplier) : await this.create();

        if (result) {
            this.hide(result);
        }
    }

    private async create(): Promise<ISupplier | null> {
        if (!this.onCreate) {
            return null;
        }

        const dto: ISupplierCreateDto = {
            name: this.tbName.text,
            description: this.taDescription.text,
            address: this.tbAddress.text,
            phone: this.phPhone.text,
            email: this.tbEmail.text,
        };

        return await this.onCreate(dto);
    }

    private async update(supplier: ISupplier): Promise<ISupplier | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: ISupplierUpdateDto = {
            sequence: parseInt(this.tbSequence.text),
            name: this.tbName.text,
            description: this.taDescription.text,
            address: this.tbAddress.text,
            phone: this.phPhone.text,
            email: this.tbEmail.text,
        };

        return await this.onUpdate(supplier, dto);
    }
}
